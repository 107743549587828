var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "row",
      },
      [
        _c(
          "div",
          {
            staticClass: "col-md-8",
          },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("datetimeRange")))]),
            _c("select-date-and-time", {
              attrs: {
                datetime: _vm.filterParams.datetimeRange,
                minuteStep: 30,
              },
              on: {
                "update:datetime": function ($event) {
                  return _vm.$set(_vm.filterParams, "datetimeRange", $event)
                },
              },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "row",
      },
      [
        _c(
          "div",
          {
            staticClass: "col-md-6",
          },
          [
            _c("br"),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2 mt-2",
                on: {
                  click: function ($event) {
                    return _vm.search()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("go")))]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default mt-2",
                on: {
                  click: function ($event) {
                    return _vm.reset()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("reset")))]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }