var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-row",
    {
      attrs: {
        "align-h": "between",
      },
    },
    [
      _c("b-col", [
        _c(
          "span",
          [
            _c("font-awesome-icon", {
              staticClass: "ml-2 mr-2",
              attrs: {
                icon: "user",
              },
            }),
            _vm._v(_vm._s(_vm.name)),
          ],
          1
        ),
      ]),
      _c("b-col", [
        _c(
          "span",
          {
            staticClass: "nowrap-content",
          },
          [
            _c("font-awesome-icon", {
              staticClass: "ml-2 mr-2",
              attrs: {
                icon: "pen",
              },
            }),
            _vm._v(_vm._s(_vm.time)),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }