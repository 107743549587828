var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("normal-data-table", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.rows,
        },
        scopedSlots: _vm._u([
          {
            key: "table-row",
            fn: function (props) {
              return [
                props.data.column.field === _vm.slotFields.vipName
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s(props.data.row.vipName))]),
                    ])
                  : _vm._e(),
                props.data.column.field === _vm.slotFields.lastEditedAt
                  ? _c(
                      "div",
                      {
                        staticClass: "last-edited-at-box",
                      },
                      [
                        _c("consultant-edited-time-content", {
                          attrs: {
                            name: props.data.row.lastEditedAt.currentConsultant
                              .name,
                            time: props.data.row.lastEditedAt.currentConsultant
                              .time,
                          },
                        }),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: `collapse-${props.data.row.id}`,
                                expression: "`collapse-${props.data.row.id}`",
                              },
                            ],
                            staticClass: "mt-2",
                            attrs: {
                              size: "sm",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("otherConsultant")))]
                        ),
                        _c(
                          "b-collapse",
                          {
                            attrs: {
                              id: `collapse-${props.data.row.id}`,
                            },
                          },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mt-1 p-1",
                              },
                              _vm._l(
                                props.data.row.lastEditedAt.otherConsultant,
                                function (info, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                    },
                                    [
                                      _c("consultant-edited-time-content", {
                                        attrs: {
                                          name: info.name,
                                          time: info.time,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.data.column.field === _vm.slotFields.performance
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s(props.data.row.performance))]),
                    ])
                  : _vm._e(),
                props.data.column.field === _vm.slotFields.operate
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.performanceHistoryModal",
                                modifiers: {
                                  performanceHistoryModal: true,
                                },
                              },
                            ],
                            staticClass: "mt-2",
                            attrs: {
                              variant: "warning",
                              size: "sm",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: {
                                icon: "list",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.addStudentPerformanceModal",
                                modifiers: {
                                  addStudentPerformanceModal: true,
                                },
                              },
                            ],
                            staticClass: "ml-2 mt-2",
                            attrs: {
                              variant: "primary",
                              size: "sm",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: {
                                icon: "pen",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("vip-performance-history-modal", {
        attrs: {
          timelineItems: _vm.timelineItems,
        },
      }),
      _c("add-student-performance-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }