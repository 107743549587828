var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "timeline-box",
    },
    [
      _c("bootstrap-vue-timeline", {
        attrs: {
          items: _vm.timelineItems,
          reverse: true,
          "date-format": _vm.dateFormat,
          variant: _vm.styleColor,
          loading: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }